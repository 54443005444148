import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { AuthGuard } from "./core/guards/auth-guard.service";
import { ContentLayoutComponent } from "./layouts/content/content-layout.component";
import { FullLayoutComponent } from "./layouts/full/full-layout.component";
import { CONTENT_ROUTES } from "./routes/content-layout.routes";
import { FULL_ROUTES } from "./routes/full-layout.routes";

const appRoutes: Routes = [
  { path: "", redirectTo: "auction", pathMatch: "full" },
  {
    path: "",
    component: FullLayoutComponent,
    data: { title: "full Views" },
    children: FULL_ROUTES,
    canActivate: [AuthGuard],
  },
  {
    path: "",
    component: ContentLayoutComponent,
    data: { title: "content Views" },
    children: CONTENT_ROUTES,
  },
  { path: "**", redirectTo: "pages/error" },
];

@NgModule({
  imports: [
    RouterModule.forRoot(appRoutes, { scrollPositionRestoration: "enabled" }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
