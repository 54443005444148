<header class="c-header__wrapper">
    <div class="c-header u-bg-brand ">
        <div class="c-header__content-wrapper">
            <div class="c-header__logo">
                <a (click)="gaService.event('menu_Logo'); activeMenu = null" [routerLink]="[ '/' ]" class="c-logo  c-logo--has-image" aria-label="Logo" title="Volkswagen">
                    <img src="./../../../assets/images/logo.svg" alt="Volkswagen" />
                </a>
            </div>

            <div class="c-header__nav">
                <ul class="c-page-head-nav">

                    <li class="c-page-head-nav__item  c-page-head-nav__item--left">
                        <a (click)="gaService.event('menu_AuctionList'); activeMenu = null" [routerLink]="[ '/auction' ]" class="c-page-head-nav__link ">
                            <span class="c-page-head-nav__link-text u-text-uppercase" routerLinkActive="active" >{{ 'auctions' | translate }}</span>
                        </a>
                    </li>

                    <li class="c-page-head-nav__item  c-page-head-nav__item--left u-hide@s">
                        <a (click)="activeMenu = null" [routerLink]="[ '/favorites' ]" class="c-page-head-nav__link  js-dropdown__button">
                            <span class="c-page-head-nav__link-text u-text-uppercase" routerLinkActive="active" >{{'favorites.main' | translate}}</span>
                        </a>
                    </li>

                    <li *ngIf="authState?.meta?.security?.grounding_enabled" class="c-page-head-nav__item c-page-head-nav__item--left">
                        <a (click)="activeMenu = null" [routerLink]="[ '/grounding/re-enter' ]" class="c-page-head-nav__link js-dropdown__button">
                            <span class="c-page-head-nav__link-text u-text-uppercase" routerLinkActive="active" >{{'grounding.main' | translate}}</span>
                        </a>
                    </li>

                    <li class="c-page-head-nav__item language-selector u-hide@m">
                        <app-change-language></app-change-language>
                    </li>

                    <!-- news -->
                    <li data-item="news" class="c-page-head-nav__item js-dropdown">
                        <div class="c-notification-center js-notification-center js-is-ready" [ngClass]="{'u-hide@m': newsCounterNotification === 0}">
                            <button (click)="gaService.event('menu_News'); activeMenu = null" [routerLink]="[ '/news/last' ]" class="c-notification-center__toggle  notification-newspaper" aria-expanded="false">
                                <span *ngIf="newsCounterNotification > 0" class="c-badge__wrapper  c-notification-center__toggle__badge">
                                    <span class="c-badge  c-badge--notification  ">
                                        {{newsCounterNotification}}
                                    </span>
                                </span>
                            </button>
                        </div>
                    </li>

                    <li data-item="notification" class="c-page-head-nav__item js-dropdown">
                        <app-notification-bell></app-notification-bell>
                    </li>

                    <!-- user-->
                    <li class="c-page-head-nav__item user-dropdown u-hide@m">
                        <a class="c-page-head-nav__link  js-dropdown__button" (click)="openDropdown('user')" [attr.aria-expanded]="activeMenu === 'user'">
                            <i class="c-page-head-nav__link-icon  c-icon  c-icon--small  c-icon--[control-user]" aria-hidden="true" role="img"></i>
                            <span class="fullname c-page-head-nav__link-text">{{fullName}}</span>
                            <i class="c-page-head-nav__link-icon  c-icon  c-icon--xsmall  c-icon--[control-arrow-bottom] u-hide@s" aria-hidden="true" role="img"></i>
                        </a>
                        <div class="c-page-head-nav__dropdown  js-dropdown__panel" [attr.aria-hidden]="activeMenu === 'user' ? false : true">
                            <ul class="c-page-head-nav__dropdown-content  c-page-head-nav__dropdown-content--list">

                                <li class="c-page-head-nav__dropdown-list-item">    
                                    <span  class="c-page-head-nav__dropdown-list-link">{{company}}</span>
                                </li>

                                <li class="c-page-head-nav__dropdown-list-item">
                                    <a (click)="gaService.event('menu_Profile'); activeMenu = null" [routerLink]="[ '/user/company' ]" class="c-page-head-nav__dropdown-list-link">
                                        {{'menu.user.settings' | translate}}
                                    </a>
                                </li>

                                <li class="c-page-head-nav__dropdown-list-item">
                                    <a (click)="activeMenu = null" [routerLink]="[ '/user/terms' ]" class="c-page-head-nav__dropdown-list-link">
                                        {{'menu.user.terms' | translate}}
                                    </a>
                                </li>

                                <li class="c-page-head-nav__dropdown-list-item">
                                    <a (click)="logout()" class="c-page-head-nav__dropdown-list-link">
                                        {{'menu.user.logout' | translate}}
                                    </a>
                                </li>

                            </ul>
                        </div>
                    </li>


                    <!-- burger -->
                    <li class="c-page-head-nav__item user-dropdown u-hide@m-up d-flex items-center">
                        <button (click)="opened = true" class="c-btn c-btn--icon-only c-btn--secondary hamburger"><i class="c-btn__icon c-icon c-icon--[semantic-menu]" aria-hidden="true" role="img"></i></button>
                    </li>
                </ul>
            </div>

        </div>
    </div>
</header>

<div *ngIf="opened" [class]="'c-jump-nav-bar'">
    <button (click)="opened = false" class="c-btn c-btn--icon-only close"><i class="c-btn__icon c-icon c-icon--[semantic-close]" aria-hidden="true" role="img"></i></button>
    <div class="o-page-wrap">
        <nav class="c-jump-nav-container">
            <div class="c-jump-nav-wrapper">
                <ul class="c-jump-nav">
                    <li class="js-jump-nav__item d-flex">
                        <app-change-language></app-change-language>
                    </li>
                    <li class="js-jump-nav__item d-flex" *ngFor="let item of mobileMenuLinks">
                        <a (click)="opened = false" [routerLink]="[item.path]" class="js-jump-nav__link" routerLinkActive="is-active">
                            {{"menu." + item.label | translate}}
                        </a>
                    </li>
                    <li class="js-jump-nav__item d-flex">
                        <a (click)="logout()" [routerLink]="['#']" class="js-jump-nav__link">
                            {{"menu.user.logout" | translate}}
                        </a>
                    </li>
                </ul>
            </div>
        </nav>
    </div>
</div>

<div class="header-layer" *ngIf="activeMenu !== null" (click)="closeDropdown()"></div>
